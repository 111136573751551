<template>
  <div class="main-page-wrapper font-gordita dark-style lg-container overflow-hidden">
    <HomeHeader :dark="true" />
    <HomeBanner />
    <HomePortfolio />
    <HowWeWork />
    <div class="overflow-hidden">
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import HomeHeader from './HomeHeader.vue';
import HomeBanner from './HomeBanner.vue';
import HomePortfolio from './HomePortfolio.vue';
import HowWeWork from './HowWeWork.vue';
import HomeFooter from './HomeFooter.vue';

export default {
  name: 'HomeComponents',
  components: {
    HomeHeader,
    HomeBanner,
    HomePortfolio,
    HowWeWork,
    HomeFooter,
  },
  methods :{
    track() {
      this.$gtag.pageview({
        page_path: window.location.href,
      });
    },
  }
}
</script>