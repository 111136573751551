import { createApp } from "vue";
import { createHead } from "unhead"
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./routes";
import "bootstrap";
import "./index.css";
import VueGtag from "vue-gtag-next";
import App from "./App.vue";

const head = createHead()
let app = createApp(App);
app.use(VueGtag, {
    property: { 
        id: "G-K7NXX557MN" 
    }   
});
app.use(head);
app.use(router);
app.use(VueEasyLightbox);
app.mount("#app");
