<template>
  <footer class="theme-footer-six">
    <div class="inner-container">
      <div class="container">
        <p class="copyright">Copyright &copy; 2023 Snakuar Inc.</p>
      </div>
    </div> <!-- /.inner-container -->
  </footer> <!-- /.theme-footer-six -->
</template>

<script>
export default {
  name: 'FooterSix'
}
</script>