import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '../pages/HomePage.vue';
import NotFound from '../pages/404';
import { trackRouter } from 'vue-gtag-next';

const routes = [
    {
        path: '/',
        component: HomePage,
        meta: {
            title: 'Snakuar Holdings | Home',
        },
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Oops, nothing here | Snakuar Holdings',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Oops, nothing here | Snakuar Holdings',
        },
    },
   
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
})

trackRouter(router);

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
