<template>
  <HomeComponents />
</template>

<script>
import HomeComponents from '../components/home/index.vue';
import { useHead } from 'unhead';

export default {
  setup() {
    useHead({
      title: 'Snakuar | Home ',
      meta: [
        {
          name: 'description',
          content: 'Venture capitalist that invests in early-stage startups, and support them through iterations to build a successful business.',
        },
        {
          name: 'keywords',
          content: 'snakuar, startup, incubator, venture, capital, invest, early-stage, startups, iterations, successful, business, idea, reality',
        },
        {
          name: 'author',
          content: 'Snakuar',
        },
        {
          name: 'robots',
          content: 'index, follow',
        },
        {
          name: 'googlebot',
          content: 'index, follow',
        },
        {
          name: 'google',
          content: 'nositelinkssearchbox',
        },
        {
          name: 'google',
          content: 'notranslate',
        },
        {
          name: 'google',
          content: 'nocrtrans',
        },
        {
          name: 'google',
          content: 'notranslate',
        }
      ]
    });
  },
  components: {
    HomeComponents,
  }
}
</script>