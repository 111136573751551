<template>
  <div>
    <div class="hero-banner-six">
      <div class="illustration-content">
        <img src="../../assets/images/shape/144.svg" alt="" class="main-img ms-auto" />
        <img src="https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/ae9da16a-2d81-49c8-25f9-ef7e16f1d900/public" alt="Discussing" class="screen-one" />
        <div class="screen-two">
          <img src="https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/805c2fa4-5b56-4d50-8f51-75b787043500/public" alt="Working" class="ms-auto" />
        </div>
        <div class="screen-three">
          <img src="https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/6cb4b653-eb19-4820-1654-45d68d78ff00/public" alt="Growing" class="ms-auto" />
        </div>
         <img src="../../assets/images/shape/145.svg" alt="" class="shapes shape-one" />
        <img src="../../assets/images/shape/147.svg" alt="" class="shapes shape-three" />
        <img src="../../assets/images/shape/149.svg" alt="" class="shapes shape-five" />
        
        <div class="shapes oval-one"></div>
        <div class="shapes oval-two"></div>

      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <h1 class="font-gilroy-bold hero-heading">
              Build your Next <span>Startup</span> with us
            </h1>
            <p class="hero-sub-heading">
              We help founders delivering innovative, aggressive solutions to unfulfilled market needs.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- VideoModal -->
    <VideoModal videoId="aXFSJTjVjw0" />
  </div>
</template>

<script>
import VideoModal from "../modals/VideoModal.vue";

export default {
  name: "HeroArea",
  components: {
    VideoModal,
  },
};
</script>
