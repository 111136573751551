<template>
  <div class="fancy-feature-twelve pt-250 md-pt-50" id="about">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-10 col-md-8 m-auto">
            <div class="title-style-six text-center">
              <h2 class="font-gilroy-bold">Our Portfolio</h2>
            </div>
            <!-- /.title-style-six -->
          </div>
        </div>

        <div class="row justify-content-center pt-50 md-pt-20">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-4 col-md-6"
          >
            <div class="snakuar-portfolio  mt-40" :class="item.class">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <a :href="item.link" target="_blank"><img :src="item.icon" class="portfolio-logo" :alt="item.title + ' logo'" /></a>
              </div>
              <div class="static-text">
                <h3>{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
              <div class="hover-text" >{{ item.hover_text }}</div>
            </div>
            <!-- /.snakuar-portfolio -->
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
    <img
      src="../../assets/images/shape/151.svg"
      alt=""
      class="shapes shape-one"
    />
  </div>
</template>

<script>
export default {
  name: "FeatureArea",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: 'https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/145954c5-48db-403d-e2e9-2ebfb0fdc700/public',
          title: "zaer",
          class: "zaer-theme",
          link: "https://zaer.net",
          subtitle: "Personalized enagement platform for brick and mortar businesses",
          hover_text: "Revolutionize your customer eXperience with personalized engagement.",
        },
        {
          id: 2,
          icon: 'https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/d49a6587-428d-4807-847d-99d90808f900/public',
          title: "Overmind Digital",
          class: "overmind-digital",
          link: "https://overmind.digital",
          subtitle: "Digital Transformation Assistant for the businesses",
          hover_text: "Transforming your business, one click at a time.",
        },
        {
          id: 3,
          icon: 'https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/3b03075c-5b4c-466b-9002-fc7507a1ea00/public',
          title: "LifeTicks",
          class: "lifeticks-theme",
          link: "#",
          subtitle: "Helping everyone to become everything they want to be",
          hover_text: "Track your life thoughts, goals, ideas, tasks and habits.",
        },
        {
          id: 4,
          icon: 'https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/67e0c36d-524d-43bb-ec55-1e5479cc4700/public',
          title: "Did he say?",
          class: "did-he-say-theme",
          link: "#",          
          subtitle: "Sharing key values of greatest governance system ever created",
          hover_text: "By The One grace, peace upon me, the day I was born, and die, and rise again.",
        },
        {
          id: 4,
          icon: 'https://imagedelivery.net/FuVWihpJ7f5G0mzhUSE87g/1d83c2e0-0a12-413a-c382-95c8392ddc00/public',
          title: "Pleez",
          class: "pleez-theme",
          link: "https://pleez.com",          
          subtitle: "Providing home services to executives, the way they like it",
          hover_text: "Who says that your home services should be un-interesting?",
        },        
      ],
    };
  },
};
</script>

<style scoped>

.portfolio-logo {
  max-height: 60;
}
@media (max-width: 1199px) {
  .snakuar-portfolio h3 {font-size: 20px;}
  .snakuar-portfolio {padding: 30px 20px;}
  .snakuar-portfolio .hover-text {left: 20px; font-size: 22px;}
}

.snakuar-portfolio {
  padding: 32px 50px 40px 44px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 17px;
  position: relative;
  z-index: 1;
}
.snakuar-portfolio:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(111.21deg, #b526a6 -2.4%, #e14e66 46.51%, #e38039 93.12%);
  border-radius: 17px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.snakuar-portfolio:hover:before {
  opacity: 1;
}
.snakuar-portfolio .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}
.snakuar-portfolio h3 {
  font-weight: 500;
  font-size: 24px;
  padding: 20px 0 20px;
}
.snakuar-portfolio .static-text {
  transition: all 0.3s ease-in-out;
}
.snakuar-portfolio .hover-text {
  font-size: 24px;
  line-height: 1.5em;
  color: #fff;
  position: absolute;
  left: 25px;
  top: 112px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.snakuar-portfolio:hover .static-text {
  opacity: 0;
}
.snakuar-portfolio:hover .hover-text {
  opacity: 1;
}
.snakuar-portfolio .js-tilt-glare {
  border-radius: 17px;
}
.zaer-theme:before {
  background: linear-gradient(111.21deg, #05658D -2.4%, #1694a9 46.51%, #00A896 93.12%);
}

.overmind-digital:before {
  background: linear-gradient(111.21deg, #6102ea -2.4%, #3267fc 46.51%, #2881ff 93.12%);
}

.did-he-say-theme:before {
  background: linear-gradient(111.21deg,  #00b6c7 -2.4%, #b7aa9a 46.4%, #e5ad34 88.12%);
}

.pleez-theme:before {
  background: linear-gradient(to right, #404080 30%, #800080 80%);
}

.lifeticks-theme:before {
  background: linear-gradient(to right, #9c6721 -2.4%, #f88267 46.4%, #f79e2b 88%);
}
</style>
