<template>
  <div class="fancy-feature-thirteen pt-250 md-pt-110" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-11 col-md-10 m-auto">
          <div class="title-style-six text-center">
            <h2 class="font-gilroy-bold">Build a unique mix of opportunities, skills with whole lot of creativity</h2>
            <p class="text-xs">
              No, we don't incubate actual eggs. But we do hatch brilliant ideas!
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>
      </div>

      <div class="sldier-wrapper mt-110 md-mt-50">
        <div id="productScreenSlider" class="carousel slide" data-bs-ride="carousel" data-interval="20000">
          <ol class="carousel-indicators justify-content-between">
            <li data-bs-target="#productScreenSlider" data-bs-slide-to="0" class="active">
              <div class="d-flex">
                <div class="icon d-flex align-items-center justify-content-center">
                  <img src="../../assets/images/icon/81.svg" alt="" />
                </div>
                <p>Ideation and Validation</p>
              </div>
            </li>
            <li data-bs-target="#productScreenSlider" data-bs-slide-to="1">
              <div class="d-flex">
                <div class="icon d-flex align-items-center justify-content-center">
                  <img src="../../assets/images/icon/81.svg" alt="" />
                </div>
                <p>Mentorship and Resources</p>
              </div>
            </li>
            <li data-bs-target="#productScreenSlider" data-bs-slide-to="2">
              <div class="d-flex">
                <div class="icon d-flex align-items-center justify-content-center">
                  <img src="../../assets/images/icon/81.svg" alt="" />
                </div>
                <p>Growth and Funding</p>
              </div>
            </li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <p class="font-rubik">The first step in incubating a startup is to help entrepreneurs validate their ideas. This can involve
                conducting market research, identifying potential customers and competitors, and assessing the feasibility
                of the idea. This step may also involve developing a minimum viable product (MVP) to test the idea with
                real customers and gather feedback.</p>
              <img src="../../assets/images/slides/stage1.avif" alt="" class="m-auto" />
            </div>
            <div class="carousel-item">
              <p class="font-rubik">Once the idea has been validated, the next step is to provide mentorship and resources to the startup.
                This can include access to experienced mentors, who can provide guidance on business strategy, marketing,
                and fundraising. Additionally, startups may need access to resources such as office space, legal and
                accounting services, and technology infrastructure.</p>
              <img src="../../assets/images/slides/stage2.avif" alt="" class="m-auto" />
            </div>
            <div class="carousel-item">
              <p class="font-rubik">The final step in incubating a startup is to help it grow and secure funding. This can involve developing
                a business plan, identifying potential investors, and preparing for pitch meetings. Startups may also need
                assistance with hiring, marketing, and product development to help them achieve their growth goals.</p>
              <img src="../../assets/images/slides/stage3.avif" alt="" class="m-auto" />
            </div>
            <img src="../../assets/images/shape/152.svg" alt="" class="shapes shape-one" />
            <img src="../../assets/images/shape/153.png" alt="" class="shapes shape-two" />
          </div>
          <p class="program-conclusion font-rubik">Overall, the goal of a startup incubation program is to provide entrepreneurs with the support, resources,
            and mentorship they need to turn their ideas into successful businesses. By following these three steps,
            startups can receive the guidance and support they need to navigate the early stages of entrepreneurship and
            set themselves up for long-term success.</p>
        </div>
      </div>
      <!-- /.sldier-wrapper -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureTwo",
};
</script>

<style scoped>

.program-conclusion {
  margin-top: 70px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  background-color: transparent;
}
</style>
